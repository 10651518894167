import { Dispatch, SetStateAction, useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Calendar from "react-calendar";
import { Col, Row } from "react-bootstrap";
import InfoTip from "../layout/InfoTip";
import Pagination from "../layout/Pagination";
import SearchResult from "../layout/SearchResult";
import TagManager from "react-gtm-module";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

// function replaceAt(str: string, index: Number, ch: string) {
//   return str.replace(/./g, (c, i) => (i === index ? ch : c));
// }

function getKeyByValue(value: string, enumObject: any) {
  const indexOfS = Object.values(enumObject).indexOf(value);
  const key = Object.keys(enumObject)[indexOfS];
  return key;
}

function formatdate(date: Date) {
  if (!date) {
    return "";
  }

  return date.getTime() - date.getTimezoneOffset() * 60000;
}

type TSearchFieldFriendlyName =
  | "Body"
  | "Headline"
  | "Edition"
  | "Issue date"
  | "Byline"
  | "Category"
  | "Issue name"
  | "Reporting credit"
  | "Section"
  | "Starting page"
  | "Rubric"
  | "Slug"
  | "Headline & Lede";
// | "Tags";

const searchFieldsDataType = {
  Headline: "string",
  Body: "string",
  Edition: "string",
  "Issue date": "date",
  Byline: "string",
  Category: "string",
  // "Issue pdf": "string",
  "Issue name": "string",
  "Reporting credit": "string",
  Section: "string",
  "Starting page": "number",
  Rubric: "string",
  Slug: "string",
  "Headline & Lede": "string",
  Tags: "string",
  // "Article pdf": "string",
};

enum searchFieldsFriendlyName {
  message = "Body",
  title = "Headline",
  edition = "Edition",
  coverDate = "Issue date",
  creator = "Byline",
  genre = "Category",
  // issue_url = "Issue pdf",
  issue_name = "Issue name",
  contributor = "Reporting credit",
  section = "Section",
  startingPage = "Starting page",
  subsection1 = "Rubric",
  subsection2 = "Slug",
  // tags = "Tags",
  headlineLede = "Headline & Lede",
  // article_url = "Article pdf",
}

const searchFieldDescription = {
  Headline: "Title of the article",
  Body: "This is full text of article",
  Edition:
    "The region in which issue is published such as U.S., Canada,Europe etc",
  "Issue date": "The day that is displayed on magazine",
  Byline: "Author of article",
  Category: "Story type such as profile, review, cover story",
  "Issue name": "If issue has a special name such as person of the year",
  "Reporting credit":
    "Credits for checking and contributing reporting ( All contributers who helped on the story beside the main writer)",
  Section:
    "Section within the magazine.e.g. Table of contents, special reports, feature",
  "Starting page": "Page on which story starts within issue",
  Rubric: "The word in top corner of each page",
  Slug: "The words on top of headline of an article",
  "Headline & Lede": "Headline and First paragraph",
};

const stringSearchParametersDescription: { [key: string]: string } = {
  "Equals to":
    "The search text is an exact match of the criteria the search is in. (Eg. Headline equals to President will give all articles that have the headline with the word “President“) ",
  Contains:
    "The search text is a similar match of the criteria the search is in. (Eg. Headline contains President will give all articles that have the headline with the word “President“ or even “Presidential“)",

  // "Does not contains":
  //   "The search text is omitted in the resulting criteria of the search. (Eg. Headline Does not contain President will give all articles that do not have the headline with the word “President“ or even “Presidential“)",
  // "Starts with": "",
  "Ends with": "",

  "Is null": "",
};

const stringSearchParameters = [
  "Equals to",
  "Contains",
  "Does not equals to",
  "Does not contains",
  "Starts with",
  "Ends with",
  // "Similar to",
  // "Is blank",
  "Is null",
];
const numberSearchParameters = [
  "Is equals to",
  "Is greater than",
  "Is greater than or equal to",
  "Is in between",
  "Is less than",
  "Is less than or equal to",
  "Is null",
  "Is not equal to",
  "Is not in between",
  "Is not null",
];

const dateSearchParameters = [
  "Is on the day",
  "Is in range",
  "Is before",
  "Is on or after",
  "Is anytime",
  "Is null",
];

const getQueryObject = (
  searchParameter: string,
  searchField: TSearchFieldFriendlyName,
  searchTerm: string,
  searchFieldDataType: string,
  setIsWholeTextSearchPerformed: Dispatch<SetStateAction<boolean>>,
  dateRanges?: Date[][]
) => {
  setIsWholeTextSearchPerformed(false);
  let queryObject: any = {
    match_phrase: { [searchField]: searchTerm },
  };
  const splitedSearchTerm = searchTerm?.split("|");
  if (splitedSearchTerm?.length > 1) {
    const orQueries = splitedSearchTerm.map((term) => ({
      match_phrase: {
        [searchField]: term.trim(),
      },
    }));

    queryObject = {
      bool: {
        should: orQueries,
      },
    };
  }

  if (searchFieldDataType === "string") {
    if (searchParameter === "Equals to" && searchTerm?.split(" ")?.length > 1) {
      setIsWholeTextSearchPerformed(true);
    }

    if (searchParameter === "Contains") {
      if (splitedSearchTerm.length > 1) {
        const orQueries = splitedSearchTerm.map((term) => ({
          regexp: {
            [searchField]: ".*" + term.trim() + ".*",
          },
        }));

        queryObject = {
          bool: {
            should: orQueries,
          },
        };
      } else {
        queryObject = {
          regexp: {
            [searchField]: ".*" + searchTerm + ".*",
          },
        };
      }
    }

    if (searchParameter === "Does not equals to") {
      queryObject = { bool: { must_not: { ...queryObject } } };
      if (searchTerm.split(" ").length > 1) setIsWholeTextSearchPerformed(true);
    }

    if (searchParameter === "Does not contains") {
      if (splitedSearchTerm.length > 1) {
        const orQueries = splitedSearchTerm.map((term) => ({
          regexp: {
            [searchField]: ".*" + term.trim() + ".*",
          },
        }));

        queryObject = {
          bool: {
            must_not: orQueries,
          },
        };
      } else {
        queryObject = {
          bool: {
            must_not: {
              regexp: {
                [searchField]: ".*" + searchTerm + ".*",
              },
            },
          },
        };
      }
    }

    if (searchParameter === "Starts with") {
      if (splitedSearchTerm.length > 1) {
        const orQueries = splitedSearchTerm.map((term) => ({
          regexp: {
            [searchField]: term.trim() + ".*",
          },
        }));

        queryObject = {
          bool: {
            should: orQueries,
          },
        };
      } else {
        queryObject = {
          regexp: {
            [searchField]: searchTerm + ".*",
          },
          // match_phrase_prefix: {
          //   [searchField]: searchTerm,
          // },
        };
      }
    }
    if (searchParameter === "Ends with") {
      if (splitedSearchTerm.length > 1) {
        const orQueries = splitedSearchTerm.map((term) => ({
          regexp: {
            [searchField]: ".*" + term.trim(),
          },
        }));

        queryObject = {
          bool: {
            should: orQueries,
          },
        };
      } else {
        queryObject = {
          regexp: {
            [searchField]: ".*" + searchTerm,
          },
        };
      }
    }
    // if (searchParameter === "Similar to") {
    //   if (splitedSearchTerm.length > 1) {
    //     const orQueries = splitedSearchTerm.map((term) => ({
    //       match: {
    //         [searchField]: {
    //           query: term.trim(),
    //           fuzziness: "AUTO",
    //         },
    //       },
    //     }));

    //     queryObject = {
    //       bool: {
    //         should: orQueries,
    //       },
    //     };
    //   } else {
    //     queryObject = {
    //       match: {
    //         [searchField]: {
    //           query: searchTerm,
    //           fuzziness: "AUTO",
    //         },
    //       },
    //     };
    //   }
    // }

    // @ts-ignore
    if (searchFieldsFriendlyName[searchField] === "Headline & Lede") {
      if (splitedSearchTerm.length > 1) {
        const orQueries = splitedSearchTerm.map((term) => ({
          multi_match: {
            query: term.trim(),
            fields: ["title", "message"],
          },
        }));

        queryObject = {
          bool: {
            should: orQueries,
          },
        };
      } else {
        queryObject = {
          multi_match: {
            query: searchTerm,
            fields: ["title", "message"],
          },
        };
      }
    }
  }
  if (searchFieldDataType === "date") {
    queryObject = {
      range: {
        [searchField]: {
          gte: searchTerm,
          lte: searchTerm,
        },
      },
    };

    if (searchParameter === "Is before") {
      queryObject = {
        range: {
          [searchField]: {
            lte: searchTerm,
          },
        },
      };
    }
    if (searchParameter === "Is in range" && dateRanges) {
      queryObject = {
        range: {
          [searchField]: {
            gte: (dateRanges[0][0] as Date).toISOString().slice(0, 10),
            lte: (dateRanges[0][1] as Date).toISOString().slice(0, 10),
          },
        },
      };
    }

    if (searchParameter === "Is on or after") {
      queryObject = {
        range: {
          [searchField]: {
            gte: searchTerm,
          },
        },
      };
    }
  }

  if (searchParameter === "Is null") {
    queryObject = {
      bool: {
        must_not: [
          {
            exists: {
              field: searchField,
            },
          },
        ],
      },
    };
  }

  return queryObject;
};

const Search = () => {
  const [searchTerms, setSearchTerms] = useState<Array<string>>([]);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [andOrs, setAndOrs] = useState<Array<string>>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(false);

  const [sortingOptions] = useState([
    "Issue Date (Most Recent)",
    "Issue Date (Earliest)",
    "Most Relevant",
  ]);
  const [selectedSortingOption, setSelectedSortingOption] = useState(
    "Issue Date (Most Recent)"
  );

  const [searchFields, setSearchFields] = useState<
    Array<TSearchFieldFriendlyName>
  >([searchFieldsFriendlyName.message]);

  const [searchParameters, setSearchParameters] = useState([
    stringSearchParameters[0],
  ]);
  const [searchParametersLists, setSearchParametersLists] = useState([
    stringSearchParameters,
  ]);

  const [searchBoxNumber, setSearchBoxNumber] = useState(1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageOffset, setPageOffset] = useState(1);
  const [totalResultNumber, setTotalResultNumber] = useState(0);
  const [resultsPerPage] = useState(20);
  const [resultsFrom, setResultsFrom] = useState(0);

  const [canPerformSearch, setCanPerformSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showInitialPage, setShowInitialPage] = useState(true);
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);
  const [isWholeTextSearchPerformed, setIsWholeTextSearchPerformed] =
    useState(false);

  const [dates, setDates] = useState([new Date()]);
  const [dateRanges, setDateRanges] = useState([[new Date(), new Date()]]);

  const history = useHistory();
  const location = useLocation();

  const { authState } = useOktaAuth();

  // sending userId to tag manager
  useEffect(() => {
    if (authState?.isAuthenticated) {
      TagManager.dataLayer({
        dataLayer: {
          event: "User Id",
          user_id: authState.accessToken?.claims?.uid,
        },
      });
    }
  }, [authState]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const qSearchTerms = query.get("searchTerms")?.split(",");
    const qSearchFields = query.get("searchFields")?.split(",");
    const qSearchParameters = query.get("searchParameters")?.split(",");
    const qAndOrs = query.get("andOrs");
    const qDateRanges = query.get("dateRanges")?.split(",");
    const qDates = query.get("dates")?.split(",");
    if (qSearchTerms?.length) {
      setSearchTerms(qSearchTerms);
      setSearchFields(qSearchFields as TSearchFieldFriendlyName[]);
      setSearchParameters(qSearchParameters as string[]);
      if (qAndOrs?.length) {
        setAndOrs(qAndOrs.split(",") as string[]);
      } else {
        setAndOrs([]);
      }
      setIsInitialLoad(false);
      setSearchBoxNumber(qSearchParameters?.length ?? 1);
      let searchParametersLists = [stringSearchParameters];
      let datesTemp = [new Date()];
      let dateRangesTemp = [[new Date(), new Date()]];

      for (let i = 0; i < qSearchTerms.length; i++) {
        if (qSearchFields) {
          const searchFieldDataType =
            searchFieldsDataType[qSearchFields[i] as TSearchFieldFriendlyName];

          let parameters = stringSearchParameters;
          if (searchFieldDataType === "number")
            parameters = numberSearchParameters;
          if (searchFieldDataType === "date") parameters = dateSearchParameters;

          searchParametersLists[i] = parameters;
        }

        if (qDates) {
          datesTemp[i] = new Date(qDates[i]);
        }

        if (qDateRanges) {
          let endDate = new Date(qDateRanges[i * 2 + 1]);
          let modifiedEndDate =
            endDate.getTime() + endDate.getTimezoneOffset() * 60000;

          dateRangesTemp[i] = [
            new Date(qDateRanges[i * 2]),
            new Date(modifiedEndDate),
          ];
        }
      }
      setDates(datesTemp);
      setDateRanges(dateRangesTemp);
      setSearchParametersLists(searchParametersLists);
    } else {
      setSearchTerms([]);
      setAndOrs([]);
      setSearchFields([searchFieldsFriendlyName.message]);
      setSearchParameters([stringSearchParameters[0]]);
      setSearchParametersLists([stringSearchParameters]);
      setDateRanges([[new Date(), new Date()]]);
      setDates([new Date()]);
      setIsInitialLoad(true);
      setSearchBoxNumber(1);
    }
    setCanPerformSearch(true);
    setResultsFrom(0);
    setCurrentPageNumber(1);
  }, [location.search]);

  useEffect(() => {
    const performSearch = async () => {
      const username = process.env.REACT_APP_TIMEKEEPER_SEARCH_USERNAME;
      const password = process.env.REACT_APP_TIMEKEEPER_SEARCH_PASSWORD;
      const url = process.env.REACT_APP_TIMEKEEPER_SEARCH_URL;

      const searchField = getKeyByValue(
        searchFields[0],
        searchFieldsFriendlyName
      ) as TSearchFieldFriendlyName;

      const searchFieldDataType = searchFieldsDataType[searchFields[0]];

      const searchTerm =
        searchFieldsDataType[searchFields[0]] === "date"
          ? dates[0]?.toISOString()?.slice(0, 10)
          : searchTerms[0]?.toLowerCase();

      let queryObject =
        searchTerm !== undefined
          ? getQueryObject(
              searchParameters[0],
              searchField,
              searchTerm,
              searchFieldDataType,
              setIsWholeTextSearchPerformed,
              [dateRanges[0]]
            )
          : undefined;

      if (isInitialLoad) {
        queryObject = { match_all: {} };
      }

      if (canPerformSearch && username && password && url && queryObject) {
        setShowInitialPage(false);
        setTotalResultNumber(0);
        setSearchResults([]);
        setIsLoading(true);

        if (andOrs.length >= 1) {
          let andQueries: Array<{}> | Array<undefined> = [];
          let orQueries: Array<{}> | Array<undefined> = [];

          for (let i = 0; i < andOrs.length; i++) {
            const currentSearchTerm =
              searchFieldsDataType[searchFields[i]] === "date"
                ? dates[i]?.toISOString()?.slice(0, 10)
                : searchTerms[i]?.toLowerCase();
            const nextSearchTerm =
              searchFieldsDataType[searchFields[i + 1]] === "date"
                ? dates[i + 1]?.toISOString().slice(0, 10)
                : searchTerms[i + 1]?.toLowerCase();

            const currentSearchField = getKeyByValue(
              searchFields[i],
              searchFieldsFriendlyName
            ) as TSearchFieldFriendlyName;
            const nextSearchField = getKeyByValue(
              searchFields[i + 1],
              searchFieldsFriendlyName
            ) as TSearchFieldFriendlyName;

            const currentSearchFieldDataType =
              searchFieldsDataType[searchFields[i]];
            const nextSearchFieldDataType =
              searchFieldsDataType[searchFields[i + 1]];

            const currentQueryObject = getQueryObject(
              searchParameters[i],
              currentSearchField,
              currentSearchTerm,
              currentSearchFieldDataType,
              setIsWholeTextSearchPerformed,
              [dateRanges[i]]
            );

            const nextQueryObject = getQueryObject(
              searchParameters[i + 1],
              nextSearchField,
              nextSearchTerm,
              nextSearchFieldDataType,
              setIsWholeTextSearchPerformed,
              [dateRanges[i + 1]]
            );

            if (andOrs[i] === "AND") {
              andQueries = [
                ...andQueries,
                {
                  ...currentQueryObject,
                },
                {
                  ...nextQueryObject,
                },
              ];
            }
            if (andOrs[i] === "OR") {
              orQueries = [
                ...orQueries,
                {
                  ...currentQueryObject,
                },
                {
                  ...nextQueryObject,
                },
              ];
            }

            queryObject = {
              bool: {
                should: [
                  andQueries.length
                    ? {
                        bool: {
                          must: [...andQueries],
                        },
                      }
                    : undefined,
                  orQueries.length
                    ? {
                        bool: {
                          should: [...orQueries],
                        },
                      }
                    : undefined,
                ].filter((item) => item),
              },
            };
          }
        }
        try {
          const results = await axios({
            url,
            auth: {
              username,
              password,
            },
            method: "POST",
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              from: resultsFrom,
              query: {
                bool: {
                  must_not: [
                    { match: { title: "contents" } },
                    { match_phrase: { title: "cover description" } },
                  ],
                  should: [{ ...queryObject }],
                },
              },
              track_scores: true,
              sort: [
                selectedSortingOption !== "Most Relevant"
                  ? {
                      coverDate: {
                        order:
                          selectedSortingOption === "Issue Date (Most Recent)"
                            ? "desc"
                            : "asc",
                      },
                    }
                  : "_score",
              ],

              highlight: {
                pre_tags: ["<mark>"],
                post_tags: ["</mark>"],
                fields: [{ title: {} }, { message: {} }],
              },
              size: resultsPerPage,
            }),
          });
          const {
            hits,
            total: { value },
          } = results.data.hits;
          setSearchResults(hits);
          setCanPerformSearch(false);
          setIsLoading(false);
          setTotalResultNumber(value);
          if (!isInitialLoad) {
            TagManager.dataLayer({
              dataLayer: { event: "Search peformed", searchTerms: searchTerms },
            });
          }
        } catch {
          setIsLoading(false);
        }
      }
    };

    performSearch();
  }, [
    canPerformSearch,
    searchTerms,
    dates,
    dateRanges,
    andOrs,
    resultsFrom,
    searchParameters,
    resultsPerPage,
    searchFields,
    selectedSortingOption,
    isInitialLoad,
    isWholeTextSearchPerformed,
  ]);

  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: 0,
          background: "#000",
          zIndex: 1,
          paddingBottom: ".5rem",
          minHeight: "9vh",
        }}
        className="container-fluid"
      >
        <Row
          style={{
            float: "right",
            color: "#FFF",
            cursor: "pointer",
            paddingTop: "1.2rem",
          }}
        >
          {isHeaderCollapsed ? (
            <Col onClick={() => setIsHeaderCollapsed(false)}>
              <InfoTip text="Show" placement="top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  className="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                  style={{ marginTop: ".6rem" }}
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </InfoTip>
            </Col>
          ) : (
            <Col onClick={() => setIsHeaderCollapsed(true)}>
              <InfoTip text="Collapse" placement="top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  className="bi bi-chevron-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  />
                </svg>
              </InfoTip>
            </Col>
          )}
        </Row>
        <Row className="pt-2">
          <Col>
            <div>
              {[...Array(searchBoxNumber).keys()].map((index: number) => {
                return (
                  <div key={index}>
                    {!isHeaderCollapsed && (
                      <div>
                        <Row className="selected-and-or pb-1">
                          {andOrs[index - 1]}
                        </Row>
                        <Row>
                          <Col
                            sm={6}
                            md={6}
                            lg={2}
                            xl={2}
                            className="select-search-types"
                          >
                            <select
                              key={index}
                              value={searchFields[index] ?? ""}
                              onChange={(e) => {
                                let datesCopy = dates;
                                datesCopy[index] = new Date();
                                setDates(datesCopy);

                                let dateRangesCopy = dateRanges;
                                dateRangesCopy[index] = [
                                  new Date(),
                                  new Date(),
                                ];
                                setDateRanges(dateRangesCopy);

                                const selectedValue = e.target
                                  .value as TSearchFieldFriendlyName;
                                let parameters = stringSearchParameters;
                                if (
                                  searchFieldsDataType[selectedValue] ===
                                  "number"
                                )
                                  parameters = numberSearchParameters;
                                if (
                                  searchFieldsDataType[selectedValue] === "date"
                                )
                                  parameters = dateSearchParameters;

                                let searchParametersListsCopy = [
                                  ...searchParametersLists,
                                ];
                                searchParametersListsCopy[index] = parameters;
                                setSearchParametersLists(
                                  searchParametersListsCopy
                                );

                                let searchParametersCopy = [
                                  ...searchParameters,
                                ];
                                searchParametersCopy[index] = parameters[0];
                                setSearchParameters(searchParametersCopy);

                                let searchFieldsCopy = [...searchFields];
                                searchFieldsCopy[index] = selectedValue;
                                setSearchFields(searchFieldsCopy);
                              }}
                              className="form-select form-select-sm "
                            >
                              {Object.values(searchFieldsFriendlyName).map(
                                (fieldName) => (
                                  <option
                                    value={fieldName}
                                    key={fieldName}
                                    title={searchFieldDescription[fieldName]}
                                  >
                                    {fieldName}
                                  </option>
                                )
                              )}
                            </select>
                          </Col>

                          <Col
                            sm={6}
                            md={6}
                            lg={2}
                            xl={2}
                            className="select-search-types"
                          >
                            <select
                              key={index}
                              value={searchParameters[index] ?? ""}
                              onChange={(e) => {
                                let datesCopy = dates;
                                datesCopy[index] = new Date();
                                setDates(datesCopy);

                                let dateRangesCopy = dateRanges;
                                dateRangesCopy[index] = [
                                  new Date(),
                                  new Date(),
                                ];
                                setDateRanges(dateRangesCopy);

                                const selectedSearchParameter = e.target
                                  .value as TSearchFieldFriendlyName;
                                let searchParametersCopy = [
                                  ...searchParameters,
                                ];
                                if (searchParametersCopy[index] !== undefined) {
                                  searchParametersCopy[index] =
                                    selectedSearchParameter;
                                } else {
                                  searchParametersCopy = [
                                    ...searchParameters,
                                    selectedSearchParameter,
                                  ];
                                }
                                setSearchParameters(searchParametersCopy);
                              }}
                              className="form-select form-select-sm"
                            >
                              {searchParametersLists[index]?.map(
                                (searchType) => (
                                  <option
                                    className="cursor-pointer"
                                    value={searchType}
                                    key={searchType}
                                    title={
                                      stringSearchParametersDescription[
                                        searchType
                                      ]
                                    }
                                  >
                                    {searchType}
                                  </option>
                                )
                              )}
                            </select>
                          </Col>

                          <Col sm={6} md={6} lg={5} xl={6} className="rounded">
                            {searchFields[index] ===
                            searchFieldsFriendlyName.coverDate ? (
                              <div className="d-flex justify-content-center">
                                <Calendar
                                  onChange={(e: any) => {
                                    if (
                                      searchParameters[index] ===
                                        "Is in range" &&
                                      e?.length === 2
                                    ) {
                                      const [startDate, endDate] = e;
                                      let dateRangesCopy: any = [...dateRanges];
                                      if (dateRangesCopy[index] !== undefined) {
                                        dateRangesCopy[index] = [
                                          startDate,
                                          endDate,
                                        ];
                                      } else {
                                        dateRangesCopy = [
                                          ...dateRangesCopy,
                                          [startDate, endDate],
                                        ];
                                      }
                                      setDateRanges(dateRangesCopy);
                                    } else {
                                      let datesCopy = [...dates];
                                      if (datesCopy[index] !== undefined) {
                                        datesCopy[index] = new Date(
                                          formatdate(e)
                                        );
                                      } else {
                                        datesCopy = [
                                          ...datesCopy,
                                          new Date(formatdate(e)),
                                        ];
                                      }
                                      setDates(datesCopy);
                                    }
                                  }}
                                  value={
                                    searchParameters[index] === "Is in range"
                                      ? (dateRanges[index] as any)
                                      : dates[index]
                                  }
                                  selectRange={
                                    searchParameters[index] === "Is in range"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            ) : (
                              <input
                                key={index}
                                type="search"
                                className="search-input form-control form-control-sm rounded"
                                placeholder={"Search text here..."}
                                aria-label="Search"
                                aria-describedby="search-addon"
                                disabled={searchParameters[index] === "Is null"}
                                value={searchTerms[index] ?? ""}
                                onChange={(e) => {
                                  let searcTermsCopy = [...searchTerms];
                                  if (searcTermsCopy[index] !== undefined) {
                                    searcTermsCopy[index] = e.target.value;
                                  } else {
                                    searcTermsCopy = [
                                      ...searcTermsCopy,
                                      e.target.value,
                                    ];
                                  }
                                  setSearchTerms(searcTermsCopy);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    setCanPerformSearch(true);
                                    setCurrentPageNumber(1);
                                    setPageOffset(1);
                                    setResultsFrom(0);
                                    history.push(
                                      `/?searchFields=${searchFields}&searchParameters=${searchParameters}&searchTerms=${searchTerms}&andOrs=${andOrs}&dates=${dates}&dateRanges=${dateRanges}`
                                    );
                                    if (isInitialLoad) setIsInitialLoad(false);
                                  }
                                }}
                              />
                            )}
                          </Col>

                          <Col
                            sm={6}
                            md={6}
                            lg={3}
                            xl={2}
                            className="d-flex justify-content-end py-1"
                            style={{ height: "1%" }}
                          >
                            <InfoTip text="Remove" placement="top">
                              <button
                                type="button"
                                className="remove-button btn btn-sm btn-light"
                                style={{ marginRight: ".3rem" }}
                                onClick={() => {
                                  if (searchBoxNumber > 1) {
                                    setSearchBoxNumber(searchBoxNumber - 1);
                                    setAndOrs((oldArray) => [
                                      ...oldArray.filter((_, i) => {
                                        return index === 0
                                          ? i !== index
                                          : i !== index - 1;
                                      }),
                                    ]);
                                    setSearchTerms((oldArray) => [
                                      ...oldArray.filter((_, i) => i !== index),
                                    ]);
                                    setSearchFields((oldArray) => [
                                      ...oldArray.filter((_, i) => i !== index),
                                    ]);
                                    setSearchParametersLists((oldArray) => [
                                      ...oldArray.filter((_, i) => i !== index),
                                    ]);
                                    setSearchParameters((oldArray) => [
                                      ...oldArray.filter((_, i) => i !== index),
                                    ]);
                                    setDates((oldArray) => [
                                      ...oldArray.filter((_, i) => i !== index),
                                    ]);
                                    setDateRanges((oldArray) => [
                                      ...oldArray.filter((_, i) => i !== index),
                                    ]);
                                  }
                                }}
                                disabled={searchBoxNumber === 1}
                              >
                                -
                              </button>
                            </InfoTip>
                            <button
                              type="button"
                              className="and-or-button btn btn-sm btn-light"
                              onClick={() => {
                                setSearchBoxNumber(searchBoxNumber + 1);
                                setAndOrs((oldArray) => [...oldArray, "AND"]);
                                setSearchTerms((oldArray) => [...oldArray, ""]);
                                setSearchFields((oldArray) => [
                                  ...oldArray,
                                  searchFieldsFriendlyName.message,
                                ]);
                                setSearchParametersLists((oldArray) => [
                                  ...oldArray,
                                  stringSearchParameters,
                                ]);
                                setSearchParameters((oldArray) => [
                                  ...oldArray,
                                  stringSearchParameters[0],
                                ]);
                                setDates((oldArray) => [
                                  ...oldArray,
                                  new Date(),
                                ]);

                                setDateRanges((oldArray) => [
                                  ...oldArray,
                                  [new Date(), new Date()],
                                ]);
                              }}
                            >
                              AND
                            </button>
                            <button
                              type="button"
                              className="and-or-button btn btn-sm btn-light mx-1"
                              onClick={() => {
                                setSearchBoxNumber(searchBoxNumber + 1);
                                setAndOrs((andOrs) => [...andOrs, "OR"]);
                                setSearchTerms((oldArray) => [...oldArray, ""]);
                                setSearchFields((oldArray) => [
                                  ...oldArray,
                                  searchFieldsFriendlyName.message,
                                ]);
                                setSearchParametersLists((oldArray) => [
                                  ...oldArray,
                                  stringSearchParameters,
                                ]);
                                setSearchParameters((oldArray) => [
                                  ...oldArray,
                                  stringSearchParameters[0],
                                ]);
                                setDates((oldArray) => [
                                  ...oldArray,
                                  new Date(),
                                ]);

                                setDateRanges((oldArray) => [
                                  ...oldArray,
                                  [new Date(), new Date()],
                                ]);
                              }}
                            >
                              OR
                            </button>
                            {index === 0 && (
                              <InfoTip text="Search" placement="top">
                                <button
                                  className="btn btn-sm btn-danger"
                                  style={{
                                    width: "3rem",
                                    marginLeft: ".2rem",
                                    padding: "5px 5px",
                                  }}
                                  onClick={() => {
                                    setCanPerformSearch(true);
                                    setCurrentPageNumber(1);
                                    setPageOffset(1);
                                    setResultsFrom(0);
                                    history.push(
                                      `/?searchFields=${searchFields}&searchParameters=${searchParameters}&searchTerms=${searchTerms}&andOrs=${andOrs}&dates=${dates}&dateRanges=${dateRanges}`
                                    );
                                    if (isInitialLoad) setIsInitialLoad(false);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor "
                                    className=" cursor-pointer"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                  </svg>
                                </button>
                              </InfoTip>
                            )}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>

        {searchResults.length > 0 && (
          <Row
            className="mt-3"
            style={{ marginLeft: ".2vw", marginRight: "1.2rem" }}
          >
            <Col xs={12} lg={3} xl={3} className="text-white mt-1">
              {`${resultsFrom + 1} -- ${
                searchResults.length + resultsFrom
              }  of ${totalResultNumber} results 
                `}{" "}
            </Col>
            <Col
              xs={12}
              lg={5}
              xl={6}
              className="d-flex justify-content-center"
            >
              <Pagination
                postsPerPage={resultsPerPage}
                totalPosts={totalResultNumber}
                setResultsFrom={setResultsFrom}
                setCanPerformSearch={setCanPerformSearch}
                currentPageNumber={currentPageNumber}
                setCurrentPageNumber={setCurrentPageNumber}
                pageOffset={pageOffset}
                setPageOffset={setPageOffset}
              />
            </Col>
            <Col xs={12} lg={4} xl={3} className="d-flex justify-content-end">
              <div style={{ display: "inline-block", paddingRight: ".2rem" }}>
                <label className="text-white mt-1">Sort by:</label>
              </div>
              <div style={{ display: "inline-block" }}>
                <select
                  className="form-select form-select-sm"
                  key={selectedSortingOption}
                  value={selectedSortingOption}
                  onChange={(e) => {
                    setSelectedSortingOption(e.target.value);
                    setCanPerformSearch(true);
                    setCurrentPageNumber(1);
                    setPageOffset(1);
                    setResultsFrom(0);
                  }}
                  style={{ color: "#000", display: "inline-block" }}
                >
                  {sortingOptions.map((sortType) => (
                    <option
                      className="cursor-pointer"
                      value={sortType}
                      key={sortType}
                    >
                      {sortType}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        )}
      </div>

      {showInitialPage ? (
        <h5 className="m-2 p-3 d-flex justify-content-center">
          Welcome! you can start your search.
        </h5>
      ) : isLoading ? (
        <div
          className="d-flex justify-content-center m-3"
          style={{ fontSize: "1.125rem" }}
        >
          Loading...
        </div>
      ) : searchResults.length > 0 ? (
        <div className="container mt-4 ">
          {searchResults.map((result, index) => {
            return (
              <div key={index}>
                <SearchResult
                  result={result}
                  isWholeTextSearchPerformed={isWholeTextSearchPerformed}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-3 container">
          <p className="font-body">
            It seems your search criteria did not lead to any results. You might
            want to try changing them. Reading the{" "}
            <Link to="/how-to-use" className="font-link">
              How to use
            </Link>{" "}
            page, or hovering over the search fields above to see their
            descriptions can also help. If you still don't get the expected
            results, please reach out to us via our{" "}
            <a
              href="https://forms.gle/DUwzu3GcWu178eoX7"
              target="_blank"
              rel="noreferrer"
              className="font-link"
            >
              {" "}
              feedback page.
            </a>{" "}
            We will get back to you shortly.
          </p>
        </div>
      )}
    </div>
  );
};

export default Search;
