import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import InfoTip from "./InfoTip";

const RenderHTML = ({ html }: any) => (
  <div dangerouslySetInnerHTML={{ __html: html }}></div>
);

let imgList = [
  "/images/issue1-01.jpg",
  "/images/issue1-02.jpg",
  "/images/issue1-03.jpg",
  "/images/issue1-04.jpg",
  "/images/issue1-05.jpg",
  "/images/issue1-06.jpg",
  "/images/issue1-07.jpg",
  "/images/issue1-08.jpg",
];

const SearchResult = ({
  result,
  isWholeTextSearchPerformed,
}: 
{
  result: any;
  isWholeTextSearchPerformed: boolean;
}) => {
  const highlight = result.highlight;
  const {
    body,
    creator,
    // startingPage,
    issue_url,
    subject,
    title,
    // coverDisplayDate,
    message,
    coverDate,
    article_images,
    issue_images,
  } = result._source;
  const [showReadMoreContent, setShowReadMoreContent] = useState(false);

  // carousel state and functions
  const [show, setShow] = useState(false);
  const handleImageActiveState = (selectedIndex: number) => {
    setActiveImageIndex(selectedIndex);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setActiveImageIndex(indexOfArticle ?? 0);
    setShow(true);
  };

  // replacing articleImage url with thumnail image url
  const articleImageName = article_images
    ? article_images[0].split("/").slice(-1)[0]
    : null;

  const thumbnailImage = article_images
    ? article_images[0].replace(articleImageName, "thumbnail.png")
    : imgList[0];

  // finding active index of article in issue images
  const indexOfArticle: number | undefined = issue_images?.indexOf(
    article_images[0]
  );
  const [activeImageIndex, setActiveImageIndex] = useState(indexOfArticle ?? 0);
  if (issue_images?.length) {
    imgList = issue_images;
  } else {
    imgList = [
      "/images/issue1-01.jpg",
      "/images/issue1-02.jpg",
      "/images/issue1-03.jpg",
      "/images/issue1-04.jpg",
      "/images/issue1-05.jpg",
      "/images/issue1-06.jpg",
      "/images/issue1-07.jpg",
      "/images/issue1-08.jpg",
    ];
  }
  // Download Image
  const [fetching, setFetching] = useState(false);
  const [, setError] = useState(false);

  const downloadFile = (url: string) => {
    const name = url.replace(/^.*[\\/]/, "");

    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => response.blob())
      .then((blob) => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style.display = "none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => setError(true));
  };

  const highlightedHtmlTitle =
    highlight?.title && `<h3> ${highlight.title} </h3>`;
  const highlightedHtmlBody =
    highlight?.message && `<div> ${highlight.message} </div>`;
  const indexOfBodyTagOpen = message.indexOf("<body>");
  const indexOfBodyTagClose = message.indexOf("</body>");

  // filtering unwated header information
  let extendedHtmlBody = message.slice(
    indexOfBodyTagOpen,
    indexOfBodyTagClose + "</body>".length
  );

  const preTag = "<mark>";
  const postTag = "</mark>";

  if (highlightedHtmlBody) {
    // Extract highlighted strings
    let highlightedHtmlBodyCopy = highlightedHtmlBody?.slice();
    let indexOfPreTag = highlightedHtmlBodyCopy?.indexOf(preTag);
    let indexOfPostTag = highlightedHtmlBodyCopy?.indexOf(postTag);
    let highlightedStrings = new Set();
    while (indexOfPreTag !== -1) {
      let highlightedString = highlightedHtmlBodyCopy.substring(
        indexOfPreTag + preTag.length,
        indexOfPostTag
      );
      highlightedStrings.add(highlightedString);

      highlightedHtmlBodyCopy = highlightedHtmlBodyCopy.substring(
        indexOfPreTag +
          preTag.length +
          highlightedString.length +
          postTag.length
      );

      indexOfPreTag = highlightedHtmlBodyCopy.indexOf(preTag);
      indexOfPostTag = highlightedHtmlBodyCopy.indexOf(postTag);
    }

    // Apply highlight for extracted highlighted strings in body(message)
    const highlightedStringsUnique = Array.from(
      highlightedStrings
    ) as Array<string>;


    if (isWholeTextSearchPerformed) {
      const regex = new RegExp(
        `${highlightedStringsUnique.join(" ")}.?`,
        "g"
      );
      extendedHtmlBody = extendedHtmlBody.replace(
        regex,
        preTag + highlightedStringsUnique.join(" ") + postTag
      );
    } else {
    for (let i = 0; i < highlightedStringsUnique.length; i++) {
      const regex = new RegExp(`[ -?]${highlightedStringsUnique[i]}[ -?]`, "g");

      extendedHtmlBody = extendedHtmlBody.replace(
        regex,
        preTag + highlightedStringsUnique[i] + postTag
      );
    }
    }
  }

  const description =
    body?.p?.length > 0
      ? body?.p.join(" ").split(" ").slice(0, 100).join(" ")
      : "";

  return (
    <div>
      <div className="p-3 shadow red-hover mb-3 search-results">
        <div className="px-1 py-2">
          <div className="font-date">
            <p className=" small text-uppercase text-muted bottom-border">
              {subject?.join(", ")}
            </p>
          </div>

          <Row className="bottom-border py-2">
            <Col xs={12} sm={12} md={7} lg={8} xl={9}>
              {highlightedHtmlTitle ? (
                <RenderHTML html={highlightedHtmlTitle} />
              ) : (
                <h3 className="font-weight-normal">
                  {title?.join(", ") + "  "}
                </h3>
              )}
            </Col>
            <Col xs={12} sm={8} md={5} lg={4} xl={3}>
              <InfoTip text="Download issue pdf" placement="top">
                <a
                  href={issue_url}
                  target="_blank"
                  download
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-outline-dark font-roborto"
                  style={{ display: "inline-block" }}
                >
                  Download
                </a>
              </InfoTip>
              <InfoTip text="Preview article" placement="top">
                <span
                  className="btn btn-sm  btn-danger font-roborto"
                  onClick={handleShow}
                  style={{
                    display: "inline-block",
                    marginLeft: ".5rem",
                  }}
                >
                  Preview
                </span>
              </InfoTip>

              {showReadMoreContent && (
                <span
                  onClick={() => setShowReadMoreContent(false)}
                  style={{
                    color: "#DA291C",
                    display: "inline-block",
                    padding: ".2rem 0 0 1.5rem",
                    cursor: "pointer",
                  }}
                >
                  <InfoTip text="Collapse">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-up mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                      />
                    </svg>
                  </InfoTip>
                </span>
              )}
            </Col>
          </Row>
        </div>

        <div style={{ minHeight: "28vh" }}>
          {showReadMoreContent ? (
            <div>
              <img
                src={thumbnailImage}
                alt=""
                height={200}
                style={{
                  float: "left",
                  margin: "0 1.5rem .1rem 0rem",
                }}
              />
              <RenderHTML html={extendedHtmlBody} />
            </div>
          ) : (
            <div>
              <img
                alt=""
                src={thumbnailImage}
                height={200}
                style={{
                  float: "left",
                  margin: "0 1.5rem .1rem 0rem",
                }}
              />
              {highlightedHtmlBody ? (
                <RenderHTML html={highlightedHtmlBody} />
              ) : (
                <div className="text-monospace ">{description + " ..."}</div>
              )}
              <span
                onClick={() => setShowReadMoreContent(true)}
                style={{ color: "#DA291C", cursor: "pointer" }}
                className="text-decoration-underline  "
              >
                {"Read more"}
              </span>
            </div>
          )}
        </div>

        <div className="w-full bg-white py-1 px-2 clearfix upper-border font-date">
          <span className="float-right">
            <span className="text-muted">{creator?.join(", ")}</span>
          </span>
          <span className="float-left text-muted">
            {new Date(coverDate).toLocaleString("en-us", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </span>
        </div>
      </div>

      <div className="container">
        <Modal
          show={show}
          onHide={handleClose}
          // backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            <h6>{title}</h6>
          </Modal.Header>
          <Modal.Body>
            <Carousel
              indicators={true}
              interval={null}
              slide={true}
              activeIndex={activeImageIndex}
              onSelect={handleImageActiveState}
            >
              {imgList?.map((imgUrl: string) => (
                <Carousel.Item key={imgUrl}>
                  <button
                    disabled={fetching}
                    onClick={() => downloadFile(imgUrl)}
                    aria-label="article image"
                    className="btn btn-outline-dark d-flex justify-content-center  mb-2  font-roborto w-100"
                  >
                    DOWNLOAD THIS ARTICLE IMAGE
                  </button>
                  <img
                    className="w-100  img-preview"
                    src={imgUrl}
                    alt={imgUrl}
                    loading="lazy"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default SearchResult;
