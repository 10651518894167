import { Col, Row } from "react-bootstrap";
import InfoTip from "./InfoTip";

const Pagination = ({
  postsPerPage,
  totalPosts,
  setResultsFrom,
  setCanPerformSearch,
  currentPageNumber,
  setCurrentPageNumber,
  pageOffset,
  setPageOffset,
}: any) => {
  const pageNumbers = [];
  const threshold = 6;

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <Row style={{ overflowX: "auto" }}>
      <nav>
        <Col>
          <ul className="pagination">
            {currentPageNumber > threshold && (
              <>
                <li key={1} className="page-item">
                  <InfoTip text="First Page" placement="left">
                    <button
                      disabled={1 === currentPageNumber}
                      className="btn btn-sm page-link cursor-pointer"
                      onClick={() => {
                        setResultsFrom(1 * postsPerPage - postsPerPage);
                        setCanPerformSearch(true);
                        setCurrentPageNumber(1);
                        setPageOffset(1);
                      }}
                    >
                      {1}
                    </button>
                  </InfoTip>
                </li>
                <span className="text-white m-1"> ... </span>
              </>
            )}
            {pageNumbers.map(
              (number) =>
                number < threshold + pageOffset &&
                number > pageOffset - threshold && (
                  <li key={number} className="page-item">
                    <InfoTip text="Page Number" placement="bottom">
                      <button
                        disabled={number === currentPageNumber}
                        className="btn btn-sm page-link cursor-pointer"
                        onClick={() => {
                          setResultsFrom(number * postsPerPage - postsPerPage);
                          setCanPerformSearch(true);
                          setCurrentPageNumber(number);
                          setPageOffset(number);
                        }}
                      >
                        {number}
                      </button>
                    </InfoTip>
                  </li>
                )
            )}
            {pageNumbers.length > threshold - 1 &&
              pageNumbers.length > currentPageNumber + threshold - 1 && (
                <>
                  <span className="text-white m-1"> ... </span>
                  <li key={pageNumbers.length} className="page-item">
                    <InfoTip text="Last Page">
                      <button
                        disabled={pageNumbers.length === currentPageNumber}
                        className="btn btn-sm page-link cursor-pointer"
                        onClick={() => {
                          setResultsFrom(
                            pageNumbers.length * postsPerPage - postsPerPage
                          );
                          setCanPerformSearch(true);
                          setCurrentPageNumber(pageNumbers.length);
                          setPageOffset(pageNumbers.length);
                        }}
                      >
                        {pageNumbers.length}
                      </button>
                    </InfoTip>
                  </li>
                </>
              )}
          </ul>
        </Col>
      </nav>
    </Row>
  );
};

export default Pagination;
