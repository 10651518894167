import { useState } from "react";
import { Row } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

const MenuBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { oktaAuth, authState } = useOktaAuth();

  // const history = useHistory();

  // useEffect(() => {
  //   if (!authState?.isAuthenticated) {
  //     history.push("/login");
  //   }
  // }, [isMenuOpen]);

  const handleMenuOpen = function (state: { isOpen: boolean }) {
    setIsMenuOpen(state.isOpen);
  };

  // const handleLogin = async () => history.push("/login");
  const handleLogout = async () => oktaAuth.signOut();

  return (
    <Row
      style={{
        background: "white",
        borderBottom: "1px solid #999999",
        height: "2.9rem",
        width: "100%",
      }}
    >
      {/* <Col xs={3} sm={4} md={5} lg={5} xl={6}> */}
      {authState?.isAuthenticated && (
        <div style={{ display: "inline-block" }}>
          <Menu onStateChange={handleMenuOpen} isOpen={isMenuOpen}>
            <div
              style={{ height: "85vh" }}
              className="d-flex  flex-column mb-3"
            >
              <div className="p-2">
                <NavLink
                  to="/"
                  exact
                  className={(isActive) =>
                    isActive ? "menu-item-active" : "menu-item"
                  }
                  onClick={() => setIsMenuOpen(false)}
                >
                  Home
                </NavLink>
              </div>
              <div className="p-2 ">
                <NavLink
                  to="/how-to-use"
                  className={(isActive) =>
                    isActive ? "menu-item-active" : "menu-item"
                  }
                  onClick={() => setIsMenuOpen(false)}
                >
                  How to use
                </NavLink>
              </div>
              <div className="p-2 ">
                <NavLink
                  to="/about"
                  className={(isActive) =>
                    isActive ? "menu-item-active" : "menu-item"
                  }
                  onClick={() => setIsMenuOpen(false)}
                >
                  About
                </NavLink>
              </div>
              <div className="mt-auto p-2">
                {/* {authState?.isAuthenticated ? ( */}
                <span
                  className="menu-item"
                  style={{ display: "block", cursor: "pointer" }}
                  onClick={() => {
                    setIsMenuOpen(false);
                    handleLogout();
                  }}
                >
                  Logout
                </span>
                {/* ) : ( */}
                {/* <span
                    className="menu-item"
                    style={{ display: "block", cursor: "pointer" }}
                    onClick={() => {
                      setIsMenuOpen(false);
                      handleLogin();
                    }}
                  >
                    Login
                  </span> */}
                {/* )} */}
                <a
                  className="menu-item pt-3"
                  href="https://forms.gle/DUwzu3GcWu178eoX7"
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "block" }}
                >
                  Feedback
                </a>
              </div>
            </div>
          </Menu>
        </div>
      )}

      {/* </Col> */}
      {/* <Col xs={6}, paddingLeft: "20vw" sm={6} md={6} lg={6} xl={6} > */}
      <div
        style={{ display: "inline-block" }}
        className="d-flex justify-content-center"
      >
        <Navbar.Brand href="/">
          <img
            src="tk.jpg"
            alt="time-keeper-logo"
            width="190vw"
            className="mt-1"
          />
        </Navbar.Brand>
      </div>

      {/* </Col> */}
    </Row>
  );
};

export default MenuBar;
