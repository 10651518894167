const otgConfig = {
  oidc: {
    issuer: `${process.env.REACT_APP_TIMEKEEPER_ISSUER}`,
    clientId: process.env.REACT_APP_TIMEKEEPER_CLIENT_ID,
    scopes: ["openid", "profile", "email"],
    redirectUri: `${window.location.origin}/login/callback`,
  },
  widget: {
    issuer: `${process.env.REACT_APP_TIMEKEEPER_ISSUER}`,
    clientId: process.env.REACT_APP_TIMEKEEPER_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ["openid", "profile", "email"],
  },
};

export default otgConfig;
