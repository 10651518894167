import "./App.css";
import { Route, useHistory } from "react-router-dom";
import MenuBar from "./components/layout/MenuBar";
import Search from "./components/pages/Search";
import About from "./components/pages/About";
import HowToUse from "./components/pages/HowToUse";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import Login from "./components/auth/Login";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const history = useHistory();

  const customAuthHandler = () => {
    history.push("/login");
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || "", window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <MenuBar />
      <SecureRoute exact path="/" component={Search} />
      <SecureRoute path="/about" exact component={About} />
      <SecureRoute path="/how-to-use" exact component={HowToUse} />
      <Route path="/login" component={Login} />
      <Route path="/login/callback" component={LoginCallback} />
    </Security>
  );
}

export default App;
