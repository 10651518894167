import { ReactElement } from "react";
import { Placement } from "react-bootstrap/esm/types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function InfoTip({
  children,
  text,
  placement = "right",
}: {
  text: string;
  placement?: Placement;
  children: ReactElement;
}) {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 650, hide: 100 }}
      overlay={
        <Tooltip id={text}>
          <span className="info-tip">{text}</span>
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
}

export default InfoTip;
