const About = () => {
  return (
    <div style={{ background: "white", height: "100vh" }}>
      <div className="container">
        <h1
          style={{
            padding: "50px 0 20px 0",
            borderBottom: "1px solid black",
            color: "black",
          }}
          className="font-heading"
        >
          ABOUT
        </h1>
        <p className="mt-3 font-body">
          TIMEKeeper is an archive of TIME print magazines since 1923. TIME
          Keeper allows users to search articles and issues by writer, issue
          date, section, and more.
        </p>

        <p className="font-body">
          TIMEKeeper is built and maintained by the Data Product team at TIME,
          using a scrum framework in which we optimize the product in
          iterations—meaning that we want to hear from you on our
          <a
            href="https://forms.gle/DUwzu3GcWu178eoX7"
            target="_blank"
            rel="noreferrer"
            className="font-link"
          >
            {" "}
            feedback page
          </a>{" "}
          about how this archive can be improved in future versions.
        </p>

        <p className="font-body">
          Special thanks to Lily Rothman for helping us understand the research
          needs of editors/journalists better.
        </p>
      </div>
    </div>
  );
};

export default About;
