const HowToUse = () => {
  return (
    <div style={{ background: "white", height: "100vh" }}>
      <div className="container">
        <h1
          style={{
            padding: "50px 0 20px 0",
            borderBottom: "1px solid black",
            color: "black",
          }}
          className="font-heading"
        >
          How to use
        </h1>
        <div className="how-to-use">
          <p className="mt-3 font-body">
            TIMEKeeper enables you to search print archives by different
            metadata available in the search fields. If you hold your mouse on a
            field, you can see the description of the field.
          </p>
          <img
            className="mb-5 rounded mx-auto d-block"
            src="/images/how-to-use/image1.png"
            alt=""
            width="70%"
          />
          <hr />
          <p className="font-body">
            You can combine search criteria by using AND/ OR buttons.
          </p>
          <img
            className="mb-5 rounded mx-auto d-block"
            src="/images/how-to-use/image4.png"
            alt=""
            width="70%"
          />
          <hr />

          <p className="font-body">
            You can also use the vertical line character "|" in between the
            search texts in the search bar to do OR operation. For example, if
            you search for "Biden | President" in the headlines, you will get
            results for all the articles with headlines that have the word Biden
            or President in them. This is especially useful when we are trying
            to perform more complex searches as
          </p>
          <img
            className="mb-5 rounded mx-auto d-block"
            src="/images/how-to-use/image5.png"
            alt=""
            width="70%"
          />
          <hr />

          <p className="font-body">
            You can collapse the search bar by using the arrow sign next to the
            search button.
          </p>
          <img
            className="mb-5 rounded mx-auto d-block"
            src="/images/how-to-use/image3.png "
            alt=""
            width="70%"
          />
          <hr />

          <p className="font-body">
            You can sort your searches by navigating the sort menu.
          </p>
          <img
            className="mb-5 rounded mx-auto d-block"
            src="/images/how-to-use/image2.png"
            alt=""
            width="70%"
          />
          <hr />

          <p className="pb-5 font-body">
            If the search you are running doesn't provide expected results, or
            if you have any suggestions on how to make TIMEKeeper better, please
            connect with us in the{" "}
            <a
              href="https://forms.gle/DUwzu3GcWu178eoX7"
              target="_blank"
              rel="noreferrer"
              className="font-link"
            >
              {" "}
              feedback section
            </a>
            {""}.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToUse;
